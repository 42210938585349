import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Sound from "react-sound";

import "./App.scss";

import Header from "./shared/header";
import { Routes } from "./Routes";
import Menu from "./shared/header/Menu";
import Footer from "./shared/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Intro from "./pages/home/components/Intro";
import AskSoundPermission from "./pages/home/components/AskSoundPermission";

const StyledContainer = styled(Container)`
  height: 100%;
`;

//Modal style
// const customStyles = {
//   overlay: {
//     backgroundColor: "rgba(0, 0, 0, 0.75)",
//   },
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     padding: "0",
//     overflow: "intial",
//     marginRight: "-50%",
//     backgroundColor: "#030303",
//     transform: "translate(-50%, -50%)",

//     span: {
//       fontSize: "58px",
//     },
//   },
// };

// const StyledModalContent = styled.div`
//   width: 100%;
//   height: 100%;
//   padding: 40px;
//   text-align: center;
//   max-width: 650px;
//   ${theme.breakpoints.down("sm")} {
//     text-align: left;
//     max-width: 90vw;
//     padding: 20px;
//     max-height: 100vh;
//     overflow-y: scroll;
//   }
// `;

// const StyledBackgroundImage = styled.img`
//   height: 100%;
//   position: absolute;
//   right: 0%;
//   bottom: 0%;
//   z-index: -1;
// `;

// const StyledCloseButton = styled.div`
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   cursor: pointer;
//   font-size: 28px;
//   z-index: 1;
// `;

// const StyledH5 = styled.h5`
//   margin-bottom: 26px;
//   ${theme.breakpoints.down("sm")} {
//     font-size: 16px;
//     margin-bottom: 20px;
//   }
// `;

function App() {
  const [isPlaying, setIsPlaying] = useState(Sound.status.STOPPED);
  const [showMenu, setShowMenu] = useState(false);
  const [showPreloader, setShowPreloader] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const { pathname } = useLocation();

  // const [modalIsOpen, setIsOpen] = React.useState(false); // modal State

  const handleSound = () => {
    setIsPlaying(Sound.status.PLAYING);
    document.body.removeEventListener("click", handleSound);
  };
  const handleSoundPermission = () => {
    if (pathname === "/final-destination") {
      return;
    }
    document
      .getElementById("SoundPermissionContainer")
      .removeEventListener("click", handleSoundPermission);
    setIsPlaying(Sound.status.PLAYING);
    document.getElementById("SoundPermissionContainer").style.display = "none";
    setShowPreloader(true);
  };

  useEffect(() => {
    if (showMenu) {
      const body = document.body;
      body.style.overflow = "hidden";
    } else {
      const body = document.body;
      body.style.overflow = "auto";
    }
  }, [showMenu]);

  useEffect(() => {
    AOS.init();
    if (pathname !== "/final-destination" && pathname !== "/saturn05nfc") {
      document.body.addEventListener("click", handleSound);
      document
        .getElementById("SoundPermissionContainer")
        .addEventListener("click", handleSoundPermission);
    } else {
      setShowApp(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname !== "/final-destination" && pathname !== "/saturn05nfc") {
      setTimeout(() => {
        document.getElementById("SoundPermissionContainer").style.display =
          "none";
        setShowPreloader(true);
      }, 4000);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/saturn05nfc") {
      setIsPlaying(Sound.status.STOPPED);
    }
  }, [pathname]);

  useEffect(() => {
    if (showPreloader) {
      setTimeout(() => {
        document.getElementById("Preloader").style.display = "none";
        setShowApp(true);
      }, 5000);
      // });
    }
  }, [showPreloader]);

  const handleToggleMenu = () => {
    if (!showMenu) {
      var element = document.getElementById("nav-icon4");
      element.classList.add("open");
    } else {
      var element2 = document.getElementById("nav-icon4");
      element2.classList.remove("open");
    }
    setShowMenu(!showMenu);
  };

  return (
    <>
      <Sound
        url="./static2.mp3"
        playStatus={isPlaying}
        loop={true}
        volume={100}
      />
      {pathname !== "/final-destination" && pathname !== "/saturn05nfc" && (
        <div id="SoundPermissionContainer">
          <AskSoundPermission />
        </div>
      )}

      {showPreloader && (
        <div id="Preloader">
          <Intro />
        </div>
      )}
      {showApp && (
        <div className="App">
          <StyledContainer maxWidth="lg">
            <Header
              showMenu={showMenu}
              onToggleMenu={handleToggleMenu}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
            {showMenu && <Menu onToggleMenu={handleToggleMenu} />}
            <Routes />
          </StyledContainer>
          <Footer />
        </div>
      )}
      {/* <div className="grain" /> */}
    </>
  );
}

export default App;
