import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Instagram,
  Facebook,
  Linkedin,
  Youtube,
  Twitter,
  LogoBlack,
} from "../img";
import { theme } from "../Theme";

const StyledFooter = styled.footer`
  background-color: #fff;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
  margin-top: 40px;
`;
const StyledTitle = styled.p`
  font-size: 46px;
  line-height: 32px;
  color: ${theme.palette.primary.dark};
  margin-bottom: 12px;
  margin-top: 0px;
  cursor: pointer;
`;
const StyledLink = styled(Link)`
  color: ${theme.palette.primary.dark};
  font-size: 24px;
  line-height: 19px;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
`;

const StyledMediaLink = styled.a`
  display: inline;
  margin-right: 8px;
  svg {
    width: 36px;
  }
`;
const StyledText = styled.p`
  color: ${theme.palette.primary.dark};
  font-size: 14px;
  line-height: 20px;
`;

const StyledLinkContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  ${theme.breakpoints.down("sm")} {
    margin-left: unset;
  }
`;

const StyledLogoContainer = styled.div`
  width: fit-content;
  margin-left: auto;

  ${theme.breakpoints.down("sm")} {
    margin-left: unset;
  }
`;

const Footer = () => {
  let history = useHistory();
  const handleTheCollection = () => {
    history.push("/collection");
  };
  const handleGoToHome = () => {
    history.push("/");
  };
  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Grid spacing={2} container>
          <Grid item xs={12} sm={4} md={4}>
            <LogoBlack cursor="pointer" onClick={handleGoToHome} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <StyledLinkContainer>
              <StyledLink to="/">THE EXPERIENCE</StyledLink>
              <StyledLink to="/collection">The Collection</StyledLink>
              <StyledLink to="/">PRIVACY POLICY</StyledLink>
              <StyledLink>
                <a
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.dark,
                  }}
                  href="mailto:club@winesfromanotherworld.com"
                >
                  Contacts
                </a>
              </StyledLink>
            </StyledLinkContainer>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <StyledLogoContainer>
              <StyledTitle>Get in touch</StyledTitle>
              <StyledMediaLink
                target="_blank"
                href="https://www.facebook.com/Wines-From-Another-World-159081955932301"
              >
                <Facebook />
              </StyledMediaLink>
              <StyledMediaLink
                target="_blank"
                href="https://twitter.com/wines_from"
              >
                <Twitter />
              </StyledMediaLink>
              <StyledMediaLink
                target="_blank"
                href="https://www.instagram.com/winesfromanotherw/"
              >
                <Instagram />
              </StyledMediaLink>
              <StyledMediaLink
                target="_blank"
                href="https://www.linkedin.com/company/wines-from-another-world/"
              >
                <Linkedin />
              </StyledMediaLink>
              <StyledMediaLink
                target="_blank"
                href="https://www.youtube.com/channel/UC94TFEfnzJDbD6QncolxydA"
              >
                <Youtube />
              </StyledMediaLink>
            </StyledLogoContainer>
          </Grid>
        </Grid>
        <Box>
          <StyledText>
            COPYRIGHT &reg;; 2021 WINES FROM ANOTHER WORLD <br /> ALL RIGHTS
            RESERVED.
          </StyledText>
          <StyledText>YOU MUST BE 18 OR OVER TO ACCESS</StyledText>
        </Box>
      </Container>
    </StyledFooter>
  );
};
export default Footer;
