import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { AuthProvider } from "./contexts/authContext";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { theme } from "./Theme";
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <AuthProvider>
      <Router>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </Router>
    </AuthProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
