import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "../../Theme";
import { space, SpaceProps, layout, LayoutProps } from "styled-system";
import useSound from "use-sound";

//@ts-ignore
import audio from "../../audio/hover.mp3";
//@ts-ignore
import audioClick from "../../audio/click.mp3";

const StyledButton = styled.button`
  border: 1px solid ${theme.palette.primary.main};
  background-color: transparent;
  box-sizing: border-box;
  padding: 12px 50px;
  text-align: center;
  color: ${theme.palette.primary.main};
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Unica One", cursive !important;
  font-weight: 400 !important;
  ${space}
  ${layout}
`;

interface Button extends SpaceProps, LayoutProps {
  label: string;
  type?: string;
  onClick?: () => void;
}

export const PrimaryBtn: FC<Button> = ({
  label,
  onClick = () => {},
  type,
  ...props
}) => {
  const [play] = useSound(audio);
  const [playClick] = useSound(audioClick);
  const handleClick = () => {
    playClick();
    onClick();
  };
  return (
    <StyledButton
      type={type}
      onClick={handleClick}
      {...props}
      className="btn"
      onMouseEnter={play}
    >
      {label}
    </StyledButton>
  );
};
