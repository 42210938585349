import React from "react";
import styled from "styled-components";
import { PrimaryBtn } from "../../../shared/Buttons";
import { typography, space } from "styled-system";
import { useHistory } from "react-router";
import { CollectionStar } from "../../../img";
import { theme } from "../../../Theme";
import { Hidden } from "@material-ui/core";
import Spacer from "../../../shared/Spacer";
import PlanetCard from "../../collection/PlanetCard";
import { planetsInformation } from "../../collection/data";

const StyledH3 = styled.h3`
  margin-bottom: 50px;
`;

const StyledH4 = styled.h4`
  ${typography}
  ${space}
`;

const StyledImg = styled.img`
  width: 100%;
  margin-top: 100px;
  &:nth-of-type(3) {
    margin-bottom: 100px;
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 60px;
  }
`;
const StyledImgPlanets = styled.img`
  width: 100%;
  margin-bottom: 130px;
  margin-top: 100px;
  ${theme.breakpoints.down("sm")} {
    margin-bottom: 50px;
  }
`;

const HomeCollection = () => {
  const history = useHistory();
  const handleGoToCollection = () => {
    return history.push("/collection");
  };
  return (
    <>
      <h1 data-aos="fade-up" data-aos-duration="2000">
        the collection
      </h1>
      <StyledH3 data-aos="fade-up" data-aos-duration="2200">
        9 planets, 9 regions, 9 producers, 9 codes, 9 wines
      </StyledH3>
      <StyledH4 data-aos="fade-up" data-aos-duration="2300" mb="50px">
        A GROUNDBREAKING EXPERIENCE, A CUTTING-EDGE VISION.
      </StyledH4>
      <StyledH4 data-aos="fade-up" data-aos-duration="2400" mb="50px">
        JUPITER_ OUR JOURNEY STARTED WITH OUR FIRST PLANET JUPITER IN PORTUGAL
        AND NOW WE ARE TAKING IT FAR BACK IN THE GALAXY.
      </StyledH4>
      <StyledH4 data-aos="fade-up" data-aos-duration="2500" mb="50px">
        URANUS_ THE SECOND DISCOVERY WAS FROM PRIORAT, A HIDDEN REGION IN THE
        MOUNTAINS OF TARRAGONA DEVASTATED BY PHYLLOXERA AT THE END OF THE 19TH
        CENTURY.
      </StyledH4>
      <StyledH4 data-aos="fade-up" data-aos-duration="2600" mb="50px">
        SATURN_ THE THIRD PLANET IS FROM A VERY DISTINCTIVE GROSSE LAGE (GRAND
        CRU) VINEYARD IN THE MOSEL VALLEY. UNCRAFTED VINES BASED ON RED SLATE
        OVER 130 YEARS OLD…
      </StyledH4>
      <StyledH4
        data-aos="fade-up"
        data-aos-duration="2500"
        letterSpacing="0.3em"
      >
        COMMENCING COUNTDOWN...
      </StyledH4>
      <PrimaryBtn
        onClick={handleGoToCollection}
        mt="80px"
        label="see collection"
        data-aos="zoom-in"
        data-aos-duration="2600"
      />
      <Hidden xsDown>
        <StyledImgPlanets
          data-aos="zoom-in"
          data-aos-duration="2000"
          src={`../images/planets.png`}
          alt="planets"
        />
      </Hidden>
      <Hidden smUp>
        <StyledImgPlanets
          data-aos="zoom-in"
          data-aos-duration="2000"
          src={`../images/planetsMobile.png`}
          alt="planets"
        />
      </Hidden>
      <PlanetCard
        planet={planetsInformation[5]}
        onPlanetclick={() =>
          history.push(`/collection/${planetsInformation[5].name}`)
        }
      />
      {/* IMAGES SATURN */}
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2000"
        src={`../images/welcomeWineSaturn1.jpg`}
        alt="Saturn"
      />{" "}
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2000"
        src={`../images/welcomeWineSaturn2.jpg`}
        alt="Saturn"
        mb="50px"
      />{" "}
      {/* IMAGES Uranus */}
      <PlanetCard
        planet={planetsInformation[6]}
        onPlanetclick={() =>
          history.push(`/collection/${planetsInformation[6].name}`)
        }
      />
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2000"
        src={`../images/welcomeWineUranus.jpg`}
        alt="uranus"
      />{" "}
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2000"
        src={`../images/welcomeWineUranus2.jpg`}
        alt="uranus"
        mb="50px"
      />{" "}
      <PlanetCard
        planet={planetsInformation[4]}
        onPlanetclick={() =>
          history.push(`/collection/${planetsInformation[4].name}`)
        }
      />
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2000"
        src={`../images/welcomeWineJupiter.png`}
        alt="jupiter"
      />
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2000"
        src={`../images/welcomeWineJupiter3.jpg`}
        alt="jupiter"
      />
      <StyledImg
        data-aos="zoom-in"
        data-aos-duration="2300"
        src={`../images/welcomeWineJupiter2.jpg`}
        alt="jupiter box"
      />
      <Spacer height="100px" />
      <CollectionStar data-aos="zoom-in" data-aos-duration="2000" />
    </>
  );
};
export default HomeCollection;
