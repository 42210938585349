import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { WelcomePlanets } from "../../../img";
import { PrimaryBtn } from "../../../shared/Buttons";

const StyledH3 = styled.h3`
  margin-top: 60px;
  margin-bottom: 30px;
`;

const GetInTouch = () => {
  return (
    <Box>
      <StyledH3>want to be part of the experience?</StyledH3>
      <a
        style={{ textDecoration: "none" }}
        href="mailto: club@winesfromanotherworld.com"
      >
        <PrimaryBtn
          display="block"
          marginX="auto"
          marginBottom="100px"
          label="get in touch"
        />
      </a>

      <WelcomePlanets />
    </Box>
  );
};
export default GetInTouch;
