import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useAuth } from "../../contexts/authContext";
import { Planet } from "../../helper";
import { theme } from "../../Theme";
import { planetsInformation } from "./data";
import PlanetCard from "./PlanetCard";
import useSound from "use-sound";
//@ts-ignore
import click from "../../audio/click.mp3";

const StyledH3 = styled.h3`
  margin-bottom: 50px;
  ${theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }
`;
const StyledText = styled.p`
  font-size: 18px;
  letter-spacing: 0.2em;
  margin: 0 0 100px 0;
  ${theme.breakpoints.down("md")} {
    margin: 0 0 50px 0;
  }
`;
const StyledGrid = styled(Grid)`
  margin-top: 130px;
  margin-bottom: 100px;
  ${theme.breakpoints.down("md")} {
    margin-top: 60px;
    margin-bottom: 50px;
  }
`;
const StyledRecomentText = styled.h3`
  margin-bottom: 10px;
`;

const Collection = () => {
  const { user } = useAuth();
  let history = useHistory();
  const [playClick] = useSound(click);
  const handlePlanetClick = (planet: Planet) => {
    history.push("/collection/" + planet.name);
    playClick();
    // if (user) {
    //   history.push("/collection/" + planet.name);
    // } else {
    //   history.push("/signup");
    // }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box textAlign="center">
      <h2>the collection</h2>
      <StyledH3>9 planets, 9 regions, 9 producers, 9 codes, 9 wines</StyledH3>
      <Container maxWidth="md">
        <StyledGrid container spacing={1}>
          {planetsInformation.map((planet) => {
            return (
              <Grid key={planet.name} item xs={12} sm={6} md={4}>
                <PlanetCard onPlanetclick={handlePlanetClick} planet={planet} />
              </Grid>
            );
          })}
        </StyledGrid>
      </Container>

      <StyledRecomentText>
        WE RECOMMEND YOU PAIR THIS EXPERIENCE WITH
      </StyledRecomentText>
      <StyledText>Mr. David Bowie "Space oddity"</StyledText>
    </Box>
  );
};
export default Collection;
