import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../contexts/authContext";
import { Box } from "@material-ui/core";
import MainInput from "../../../shared/Input";
import { PrimaryBtn } from "../../../shared/Buttons";
import styled from "styled-components";
import { theme } from "../../../Theme";
import { useHistory } from "react-router";

let schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Must be a valid email")
      .required("Email is required"),
  })
  .defined();

const FullHeightContainer = styled.div`
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -170px;
  flex-direction: column;
`;

const StyledError = styled.div`
  color: ${theme.palette.error.main};
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface recoverPasswordSchema {
  email: string;
}

const Login = () => {
  const { changePassword } = useAuth();
  const [responseMessage, setResponseMessage] = useState<string>("");
  let history = useHistory();

  const { control, handleSubmit, getValues, errors, formState, trigger } =
    useForm<recoverPasswordSchema>({
      defaultValues: { email: "" },
      mode: "onChange",
      resolver: yupResolver(schema),
    });

  const onSubmit = async () => {
    const { email } = getValues();
    setResponseMessage("");
    trigger();
    if (formState.isValid) {
      changePassword(email);
      setResponseMessage(
        "You will receive an e-mail shortly. Make sure to check your inbox."
      );
      setTimeout(() => {
        history.push("/login");
      }, 2500);
    } else {
      setResponseMessage("E-mail not valid");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FullHeightContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Controller
            name="email"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="10px"
                id="standard-basic"
                label="Email"
                helperText={errors.email?.message}
              />
            }
          />
        </Box>
        <PrimaryBtn
          marginTop="50px"
          onClick={onSubmit}
          label="recover password"
        />
        <StyledError>{responseMessage}</StyledError>
      </form>
    </FullHeightContainer>
  );
};
export default Login;
