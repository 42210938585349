import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../../contexts/authContext";
import { theme } from "../../../Theme";
import CodeSignUp from "./components/CodeSignUp";
import SignUpForm from "./components/signUpForm";
import WelcomeMessage from "./components/WelcomeMessage";

const SignUp = () => {
  const { user } = useAuth();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const [code, setCode] = useState<string | null>(
    user?.user_metadata?.code || null
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {!showWelcomeMessage && (
        <>
          {!code && !user && <CodeSignUp setCode={setCode} />}
          {(code || user) && (
            <div className="form-wrapper">
              <SignUpForm
                setShowWelcomeMessage={() => setShowWelcomeMessage(true)}
                code={code}
              />
            </div>
          )}
        </>
      )}

      {showWelcomeMessage && <WelcomeMessage />}
    </>
  );
};
export default SignUp;
