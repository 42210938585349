import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Planet } from "../../helper";
import Planetimage from "../../shared/PlanetImage";
import { theme } from "../../Theme";
import useSound from "use-sound";

//@ts-ignore
import audio from "../../audio/hover.mp3";

const StyledCode = styled.p<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    font-size: 18px;
    line-height: 21px;
    color: ${disabled
      ? theme.palette.secondary.dark
      : theme.palette.primary.light};
    margin-bottom: 5px;
  `};
`;
const StyledTitle = styled.p<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    font-size: 28px;
    line-height: 28px;
    color: ${disabled
      ? theme.palette.secondary.dark
      : theme.palette.primary.light};
    letter-spacing: 0.2em;
  `};
`;
const StyledBox = styled(Box)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};
    cursor: pointer;
  `};
`;
const StyledLocation = styled.p`
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.2em;
`;

interface PlanetCardProps {
  planet: Planet;
  onPlanetclick: (planet: Planet) => void;
}

const PlanetCard: FC<PlanetCardProps> = ({ planet, onPlanetclick }) => {
  const [play] = useSound(audio);
  return (
    <StyledBox
      disabled={planet.disabled}
      onClick={() => onPlanetclick(planet)}
      onMouseEnter={play}
      mb="40px"
      textAlign="center"
      className="planet"
    >
      <Planetimage planetName={planet.name} />
      <StyledCode disabled={planet.disabled}>
        {"code " + planet.code}
      </StyledCode>
      <StyledTitle disabled={planet.disabled}>{planet.name}</StyledTitle>
      {!planet.disabled && <StyledLocation>{planet.location}</StyledLocation>}
    </StyledBox>
  );
};
export default PlanetCard;
