import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import HomeCollection from "./components/HomeCollection";
import Welcome from "./components/Welcome";
import GetInTouch from "./components/GetInTouch";

// Component
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box textAlign="center">
      <Welcome />
      <Box mt="100px" />
      <HomeCollection />
      <GetInTouch />
    </Box>
  );
};
export default Home;
