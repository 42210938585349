export enum Planets {
  MERCURY = "mercury",
  VENUS = "venus",
  EARTH = "earth",
  MARS = "mars",
  JUPITER = "jupiter",
  SATURN = "saturn",
  URANUS = "uranus",
  NEPTUNE = "neptune",
  PLUTO = "pluto",
}

export type Planet = {
  code: string;
  name: string;
  fileLink: string;
  location: string;
  adjectives: string[];
  station: string;
  rocket: string;
  destination: string;
  slider1: string[];
  slider2: string[];
  slider3: string[];
  disabled?: boolean;
  release?: string;
  nfc?: string[];
};
