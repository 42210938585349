import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
const FullHeightContainer = styled.div`
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: all 0.5 ease-in-out;
`;
const StyledTitle = styled.h3`
  letter-spacing: 0.05em;
`;

const AskSoundPermission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FullHeightContainer>
      <StyledTitle>
        for a better experience, click here to enable sound
      </StyledTitle>
    </FullHeightContainer>
  );
};
export default AskSoundPermission;
