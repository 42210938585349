import { Box } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import PlanetWelcomeCard from "../../shared/PlanetWelcomeCard";
import { planetsInformation } from "../collection/data";
import styled, { css } from "styled-components";
import { theme } from "../../Theme";
import Planetimage from "../../shared/PlanetImage";
import { PrimaryBtn } from "../../shared/Buttons";
import { Link } from "react-router-dom";
import { flex } from "styled-system";
import { Reciclagem } from "../../img";
import Slider from "../../shared/Slider";
import { Planets } from "../../helper";

const StyledCode = styled.p<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    font-size: 18px;
    line-height: 21px;
    color: ${disabled
      ? theme.palette.secondary.dark
      : theme.palette.primary.light};
    margin-bottom: 0px;
  `};
`;
const StyledTitle = styled.p<{ disabled?: boolean }>`
  margin-top: 0px;
  margin-bottom: 0;
  ${({ disabled }) => css`
    font-size: 36px;
    line-height: 28px;
    color: ${disabled
      ? theme.palette.secondary.dark
      : theme.palette.primary.light};
    letter-spacing: 0.2em;
  `};
`;
const StyledBox = styled(Box)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};
    cursor: pointer;
  `};
`;
const StyledPlanetImage = styled(Planetimage)`
  height: 84px;
  width: 84px;
`;

const StyledHr = styled.hr`
  border-bottom: 2px solid white;
  margin-top: 50px;
  margin-bottom: 50px;
`;
const StyledProductData = styled.div`
  font-size: 28px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-transform: uppercase;
  span {
    font-size: 20px;
  }
`;

const StyledRecycleImage = styled(Reciclagem)`
  width: 250px;
`;

const SaturnNfc = () => {
  const ref = useRef(null);
  const planet = planetsInformation.find((el) => el.name === Planets.SATURN);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Slider images={planet?.nfc} canSwipe duration={2500} />
      <StyledBox mb="40px" textAlign="left">
        <StyledPlanetImage planetName={planetsInformation[5].name} />
        <StyledCode>{"code " + planetsInformation[5].code}</StyledCode>
        <StyledTitle>{planetsInformation[5].name}</StyledTitle>
      </StyledBox>
      <Box fontSize={24}>
        VINIFICATION THIS WINE WAS MADE FROM A SELECTION OF 100% HEALTHY FRUIT.
        <br />
        THE FERMENTATION WAS DONE WITH INDIGENOUS YEAST IN A SIZEABLE FUDRE
        BARREL AND WAS LEFT AFTER FERMENTATION FOR EIGHT YEARS WITHOUT
        BATONNAGE. AFTER EIGHT YEARS ON THEIR YEASTS IN THE FUDRE BARREL, THE
        WINE WAS RACKED OFF THEIR YEAST AND WAS BOTTLED WITHOUT FINING AND
        FILTRATION.
      </Box>
      <StyledHr />
      <div id="productData">
        <StyledTitle>PRODUCT DATA</StyledTitle>
        <Box>
          <StyledProductData>
            &gt; Product Code <br />
            <span>Code_05</span>
          </StyledProductData>
          <StyledProductData>
            &gt; COUNTRY OF MANUFACTURING <br />
            <span>GERMANY</span>
          </StyledProductData>
          <StyledProductData>
            &gt; MANUFACTURING INFO URL <br />
            <span>WWW.WINESFROMANOTHERWORLD.COM</span>
          </StyledProductData>
          <Box className="mobileBtn" display="flex">
            <Link
              to="/files/product_data_sheet_saturn.pdf"
              target="_blank"
              download={true}
              data-aos="fade-up"
              data-aos-duration="2200"
              className="mobileBtn"
            >
              <PrimaryBtn
                onClick={() => null}
                mt="20px"
                label="PRODUCT DATA SHEET"
                width="100%"
              />
            </Link>
          </Box>
        </Box>
      </div>
      <StyledHr />
      <div id="materialData">
        <StyledTitle>Box Material DATA</StyledTitle>
        <Box>
          <StyledProductData>
            &gt; MATERIAL NAME <br />
            <span>RPLA MATTE NCS STONE GREY</span>
          </StyledProductData>
          <StyledProductData>
            &gt; MATERIAL CODE <br />
            <span>MATL0194</span>
          </StyledProductData>
          <StyledProductData>
            &gt; MATERIAL BRAND <br />
            <span>LOSTBOYSLAB</span>
          </StyledProductData>
          <StyledProductData>
            &gt; COLOR CODE <br />
            <span>NCS S 2002-R</span>
          </StyledProductData>
          <StyledProductData>
            &gt; MATERIAL ORIGIN COUNTRY GEO <br />
            <span>BENELUX, EUROPE</span>
          </StyledProductData>
          <Box className="mobileBtn" display="flex" gridGap={"50px"}>
            <Link
              to="/files/Material_data_mds.pdf"
              target="_blank"
              download={true}
              data-aos="fade-up"
              data-aos-duration="2200"
              className="mobileBtn"
            >
              <PrimaryBtn
                onClick={() => null}
                mt="20px"
                label="MATERIAL DATA MDS"
                width="100%"
              />
            </Link>
            <Link
              to="/files/Material_safety_data_mds.pdf"
              target="_blank"
              download={true}
              data-aos="fade-up"
              data-aos-duration="2200"
              className="mobileBtn"
            >
              <PrimaryBtn
                onClick={() => null}
                mt="20px"
                label="MATERIAL SAFETY DATA MDS"
                width="100%"
              />
            </Link>
          </Box>
        </Box>
      </div>
      <StyledHr />
      <div id="recyclingData">
        <StyledTitle>RECYCLING</StyledTitle>
        <Box>
          <StyledProductData>
            &gt; BIODERIVED <br />
            <span>YES</span>
          </StyledProductData>
          <StyledProductData>
            &gt; BIODEGRADABLE COMMENT <br />
            <span>
              YES, DEGRADABLE IN 60 YEARS, SO ONLY REALLY DEGRADABLE IN WASTE
              PLANTS.
            </span>
          </StyledProductData>
          <StyledProductData>
            &gt; MATERIAL CHEMICAL NAME <br />
            <span>RECYCLED POLYLACTIDE</span>
          </StyledProductData>
          <StyledProductData>
            &gt; RECYCLABLE <br />
            <span>Yes</span>
          </StyledProductData>
          <StyledProductData>
            &gt; RECYCLING INFORMATION <br />
            <span>
              PLA MATL0192 IS A BIODERIVED MATERIAL ORIGINALLY PRODUCED FROM
              CORN STARCH, BUT RECYCLED AGAIN FROM FOOD PACKAGING WASTE SOURCED
              IN THE BENELUX REGION. SAND IS DEVELOPED IN MATTE RPLA DESIGNED
              WITH MATCHING TO NCS (NATURAL COLOR SYSTEM) S 1505-Y30R.
            </span>
          </StyledProductData>
          <StyledProductData>
            &gt; RESIN CODE & RECYCLING <br />
          </StyledProductData>
        </Box>
      </div>
      <StyledHr />
      <div id="manufacturer">
        <StyledTitle>MANUFACTURER</StyledTitle>
        <Box>
          <StyledProductData>
            &gt; NAME <br />
            <span>WINES FROM ANOTHER WORLD</span>
          </StyledProductData>
          <StyledProductData>
            &gt; EMAIL <br />
            <span>INFO@WINESFROMANOTHERWORLD.COM</span>
          </StyledProductData>
          <StyledProductData>
            &gt; MATERIAL CHEMICAL NAME <br />
            <span>RECYCLED POLYLACTIDE</span>
          </StyledProductData>
          <StyledProductData>
            &gt; RECYCLABLE <br />
            <span>Yes</span>
          </StyledProductData>
          <StyledProductData>
            &gt; RECYCLING INFORMATION <br />
            <span>
              PLA MATL0192 IS A BIODERIVED MATERIAL ORIGINALLY PRODUCED FROM
              CORN STARCH, BUT RECYCLED AGAIN FROM FOOD PACKAGING WASTE SOURCED
              IN THE BENELUX REGION. SAND IS DEVELOPED IN MATTE RPLA DESIGNED
              WITH MATCHING TO NCS (NATURAL COLOR SYSTEM) S 1505-Y30R.
            </span>
          </StyledProductData>
          <StyledProductData>
            &gt; RESIN CODE & RECYCLING <br />
            <StyledRecycleImage />
          </StyledProductData>
        </Box>
      </div>
    </div>
  );
};
export default SaturnNfc;
