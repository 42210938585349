import React, { FC } from "react";
import styled from "styled-components";
import { Planets } from "../helper";
import {
  Mercury,
  Venus,
  Earth,
  Mars,
  Jupiter,
  Saturn,
  Uranus,
  Neptune,
  Pluto,
} from "../img/index";

const StyledPlanetImage = styled.img`
  width: 70px;
  height: 110px;
`;

interface PlanetCardProps {
  planetName?: string;
  className?: string;
}

const Planetimage: FC<PlanetCardProps> = ({ planetName, className }) => {
  let image = "";
  switch (planetName) {
    case Planets.MERCURY:
      image = Mercury;
      break;
    case Planets.VENUS:
      image = Venus;
      break;
    case Planets.EARTH:
      image = Earth;
      break;
    case Planets.MARS:
      image = Mars;
      break;
    case Planets.JUPITER:
      image = Jupiter;
      break;
    case Planets.SATURN:
      image = Saturn;
      break;
    case Planets.URANUS:
      image = Uranus;
      break;
    case Planets.NEPTUNE:
      image = Neptune;
      break;
    case Planets.PLUTO:
      image = Pluto;
      break;
    default:
      return <div>{planetName}</div>;
  }
  return (
    <StyledPlanetImage
      src={image}
      className={className + " planet-image-animation"}
      alt={planetName}
    />
  );
};
export default Planetimage;
