import React, { FC, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";
import { PrimaryBtn } from "../../../../shared/Buttons";
import MainInput from "../../../../shared/Input";
import { useAuth } from "../../../../contexts/authContext";
import { Auth0UserProfile } from "auth0-js";
import { theme } from "../../../../Theme";
const createSignUpSchema = (user?: Auth0UserProfile | null) => {
  let schema = yup
    .object()
    .shape({
      password: user
        ? yup.string().nullable()
        : yup.string().required("Password is required"),
      email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
      name: yup.string().required("Name is required"),
      phoneNumber: yup.string().required("Phone Number is required"),
      address: yup.string().required("Address is required"),
      postalCode: yup.string(),
      company: yup.string(),
      job: yup.string(),
      iconicWines: yup.string(),
      wineRegions: yup.string(),
      interests: yup.string(),
    })
    .defined();
  return schema;
};

const StyledError = styled.div`
  color: ${theme.palette.error.main};
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const SignUpContainer = styled.div<{ step: number }>`
  ${({ step }) => css`
    text-align: center;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: ${step === 2 ? "-100px" : "-170px"};
    flex-direction: column;
    ${theme.breakpoints.down("sm")} {
      height: ${step === 2 ? "100%" : "100vh"};
      margin-top: ${step === 2 ? "0" : "-100px"};
    }
  `};
`;

export interface signUpSchema {
  password: string;
  email: string;
  name: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  job: string;
  iconicWines: string;
  wineRegions: string;
  interests: string;
  company: string;
}

interface signUpProps {
  code: string | null;
  setShowWelcomeMessage: () => void;
}
const SignUpForm: FC<signUpProps> = ({ code, setShowWelcomeMessage }) => {
  const [step, setStep] = useState(1);
  const { register, user, updateUser } = useAuth();
  const [error, setError] = useState(null);

  const defaultValues = {
    email: user?.email || "",
    name: (user?.user_metadata?.name as string) || "",
    phoneNumber: (user?.user_metadata?.phoneNumber as string) || "",
    address: (user?.user_metadata?.address as string) || "",
    postalCode: (user?.user_metadata?.postalCode as string) || "",
    job: (user?.user_metadata?.job as string) || "",
    iconicWines: (user?.user_metadata?.iconicWines as string) || "",
    wineRegions: (user?.user_metadata?.wineRegions as string) || "",
    interests: (user?.user_metadata?.interests as string) || "",
    company: (user?.user_metadata?.company as string) || "",
    password: "",
  };

  const { control, errors, trigger, formState, getValues, watch } =
    useForm<signUpSchema>({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(createSignUpSchema(user)),
      shouldUnregister: false,
    });
  const watchFields = watch(["password", "email", "name"]);

  const handleNext = () => {
    trigger();
    if (
      user ||
      (watchFields.email && watchFields.password && watchFields.name)
    ) {
      setStep(2);
    }
  };
  const onSubmit = async () => {
    const {
      name,
      email,
      password,
      address,
      company,
      iconicWines,
      interests,
      job,
      phoneNumber,
      postalCode,
      wineRegions,
    } = getValues();
    const userMetaData = {
      code: code,
      address,
      company,
      iconicWines,
      interests,
      job,
      phoneNumber,
      postalCode,
      wineRegions,
      name,
    };
    trigger();
    if (formState.isValid) {
      if (user) {
        return updateUser(user.user_id, userMetaData);
      } else {
        register(email, password, userMetaData)
          .then((res) => {
            if (res.accessToken) {
              setShowWelcomeMessage();
            }
          })
          .catch((error) => {
            setError(error?.description);
          });
      }
    }
  };

  const firstForm = () => {
    return (
      <>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Controller
            name="name"
            control={control}
            as={
              <MainInput
                disabled={!!user}
                mx="20px"
                my="30px"
                label="Name"
                helperText={errors.name?.message}
              />
            }
          />
          <Controller
            name="email"
            control={control}
            as={
              <MainInput
                disabled={!!user}
                mx="20px"
                my="30px"
                label="Email"
                type="text"
                helperText={errors.email?.message}
              />
            }
          />
          {!user && (
            <Controller
              name="password"
              control={control}
              as={
                <MainInput
                  style={{ textTransform: "lowercase" }}
                  disabled={!!user}
                  mx="20px"
                  my="30px"
                  label="Password"
                  type="password"
                  helperText={errors.password?.message}
                />
              }
            />
          )}
        </Box>
        <PrimaryBtn marginTop="50px" onClick={handleNext} label="Next" />
      </>
    );
  };

  const secondForm = () => {
    return (
      <>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Controller
            name="phoneNumber"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="Phone Number"
                helperText={errors.phoneNumber?.message}
              />
            }
          />

          <Controller
            name="address"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="Address"
                type="text"
                helperText={errors.address?.message}
              />
            }
          />
          <Controller
            name="postalCode"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="Postal Code"
                helperText={errors.postalCode?.message}
              />
            }
          />
          <Controller
            name="company"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="company"
                helperText={errors.company?.message}
              />
            }
          />
          <Controller
            name="job"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="Current job title"
                helperText={errors.job?.message}
              />
            }
          />
          <Controller
            name="iconicWines"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="3 most iconic wines"
                helperText={errors.iconicWines?.message}
              />
            }
          />
          <Controller
            name="wineRegions"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="3 PREFERABLE WINE REGIONS"
                helperText={errors.wineRegions?.message}
              />
            }
          />
          <Controller
            name="interests"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="30px"
                id="standard-basic"
                label="interests"
                helperText={errors.interests?.message}
              />
            }
          />
        </Box>
        <PrimaryBtn marginTop="50px" onClick={onSubmit} label="submit" />
        <StyledError>{error}</StyledError>
      </>
    );
  };

  return (
    <SignUpContainer step={step}>
      {step === 1 && firstForm()}
      {step === 2 && secondForm()}
    </SignUpContainer>
  );
};
export default SignUpForm;
