import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../contexts/authContext";
import { Box } from "@material-ui/core";
import MainInput from "../../../shared/Input";
import { PrimaryBtn } from "../../../shared/Buttons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../Theme";
import useSound from "use-sound";
//@ts-ignore
import click from "../../../audio/click.mp3";
//@ts-ignore
import hover from "../../../audio/hover.mp3";

let schema = yup
  .object()
  .shape({
    password: yup.string().required("Password is required"),
    email: yup
      .string()
      .email("Must be a valid email")
      .required("Email is required"),
  })
  .defined();

const FullHeightContainer = styled.div`
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -170px;
  flex-direction: column;
`;
const StyledForgotPassword = styled.div`
  margin-top: 45px;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  span {
    color: #8a8a8a;
  }
`;
const StyledError = styled.div`
  color: ${theme.palette.error.main};
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface loginSchema {
  password: string;
  email: string;
}

const Login = () => {
  const { login } = useAuth();
  const [responseMessage, setResponseMessage] = useState<string>("");
  let history = useHistory();
  const [playHover] = useSound(hover);
  const [playClick] = useSound(click);

  const { control, handleSubmit, getValues, errors, formState, trigger } =
    useForm<loginSchema>({
      defaultValues: { email: "", password: "" },
      mode: "onChange",
      resolver: yupResolver(schema),
    });

  const onSubmit = async () => {
    const { email, password } = getValues();
    trigger();
    if (formState.isValid) {
      await login(email, password)
        .then((res) => {
          if (res.accessToken) setResponseMessage("login successful");
          history.push("/collection");
        })
        .catch((error) => {
          setResponseMessage(error?.description);
        });
    }
  };

  const handleForgotPassword = () => {
    playClick();
    history.push("/recover-password");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FullHeightContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
        >
          <Controller
            name="email"
            control={control}
            as={
              <MainInput
                mx="20px"
                my="10px"
                id="standard-basic"
                label="Email"
                helperText={errors.email?.message}
              />
            }
          />

          <Controller
            name="password"
            control={control}
            as={
              <MainInput
                style={{ textTransform: "lowercase" }}
                mx="20px"
                my="10px"
                id="standard-basic"
                label="Password"
                type="password"
                helperText={errors.password?.message}
              />
            }
          />
        </Box>
        <PrimaryBtn marginTop="50px" onClick={onSubmit} label="sign in" />
        <StyledError>{responseMessage}</StyledError>
        <StyledForgotPassword
          onMouseEnter={() => playHover()}
          onClick={handleForgotPassword}
        >
          Forgot your password? <span> Click here to recover</span>
        </StyledForgotPassword>
      </form>
    </FullHeightContainer>
  );
};
export default Login;
