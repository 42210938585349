import React from "react";
import styled from "styled-components";
import { WelcomeEye } from "../../../img";
import { planetsInformation } from "../../collection/data";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PlanetWelcomeCard from "../../../shared/PlanetWelcomeCard";

const StyledWelcomeEye = styled(WelcomeEye)`
  margin-top: 76px;
`;
const StyledH4 = styled.h4`
  letter-spacing: 0.3em;
  margin-top: 30px;
`;

const StyledImg = styled.img`
  width: 100%;
  margin-top: 100px;
  margin-bottom: 22px;
`;

const Welcome = () => {
  const history = useHistory();
  return (
    <>
      <h1 data-aos="fade-up" data-aos-duration="2000">
        Welcome
      </h1>
      <h4 data-aos="fade-up" data-aos-duration="2200">
        TO WINES FROM ANOTHER WORLD, <br /> AN INTERGALACTIC WINE COLLECTION
        &amp; EXPERIENCE.
      </h4>
      <br />
      <StyledH4 data-aos="fade-up" data-aos-duration="2400">
        ground control to...
      </StyledH4>
      <Grid spacing={3} container>
        <Grid item xs={12} md={4}>
          <>
            <StyledImg
              data-aos="zoom-in"
              data-aos-duration="2000"
              src={`../images/jupiter/jupiter_welcome.png`}
              alt="Jupiter"
            />
            <PlanetWelcomeCard
              planet={planetsInformation[4]}
              onPlanetclick={() =>
                history.push(`/collection/${planetsInformation[4].name}`)
              }
            />
          </>
        </Grid>
        <Grid item xs={12} md={4}>
          <>
            <StyledImg
              data-aos="zoom-in"
              data-aos-duration="2000"
              src={`../images/uranus/uranus_welcome.png`}
              alt="Uranus"
            />
            <PlanetWelcomeCard
              planet={planetsInformation[6]}
              onPlanetclick={() =>
                history.push(`/collection/${planetsInformation[6].name}`)
              }
            />
          </>
        </Grid>
        <Grid item xs={12} md={4}>
          <>
            <StyledImg
              data-aos="zoom-in"
              data-aos-duration="2000"
              src={`../images/saturn/saturn_welcome.png`}
              alt="Saturn"
            />
            <PlanetWelcomeCard
              planet={planetsInformation[5]}
              onPlanetclick={() =>
                history.push(`/collection/${planetsInformation[5].name}`)
              }
            />
          </>
        </Grid>
      </Grid>

      <StyledWelcomeEye data-aos="zoom-in" data-aos-duration="2200" />
    </>
  );
};
export default Welcome;
