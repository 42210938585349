import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
const FullHeightContainer = styled.div`
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: all 0.5 ease-in-out;
`;
const StyledTitle = styled.div`
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
`;
const StyledName = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.4em;
`;

const Intro = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FullHeightContainer>
      <StyledTitle
        data-aos="zoom-in"
        data-aos-delay="40"
        data-aos-duration="3000"
      >
        “GOD WILLS, MAN DREAMS, THE WORK IS BORN... ”
      </StyledTitle>
      <StyledName
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="2900"
      >
        Fernando Pessoa
      </StyledName>
    </FullHeightContainer>
  );
};
export default Intro;
