import firebase from 'firebase'
const firebaseConfig = {
    apiKey: "AIzaSyBFMzp84zuM8Lr2SU0T7mDwgFXVCj6k9NQ",
    authDomain: "wfaw-27aff.firebaseapp.com",
    projectId: "wfaw-27aff",
    storageBucket: "wfaw-27aff.appspot.com",
    messagingSenderId: "80697430109",
    appId: "1:80697430109:web:75e08a7508e054eba8bdb4",
    measurementId: "G-H5VXBM3B5C"
};
  
const firebaseApp=firebase.initializeApp(firebaseConfig);
const db=firebase.firestore();

export default db;