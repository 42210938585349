import React, { FC, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Box } from "@material-ui/core";
import { useAuth } from "../../contexts/authContext";
import useSound from "use-sound";
//@ts-ignore
import audio from "../../audio/hover.mp3";
//@ts-ignore
import click from "../../audio/click.mp3";
import { Link, useLocation } from "react-router-dom";

const FullHeightContainer = styled.div`
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -100px;
  flex-direction: column;
`;

const StyledText = styled(Box)`
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 25px;
  cursor: pointer;
`;
interface MenuProps {
  onToggleMenu: () => void;
}

const Menu: FC<MenuProps> = ({ onToggleMenu }) => {
  const [play] = useSound(audio);
  const [playClick] = useSound(click);
  const { user, logout } = useAuth();
  let history = useHistory();
  const { pathname } = useLocation();
  const scollToRefre = useRef();

  const handleNavigatePress = () => {
    playClick();
    onToggleMenu();
    history.push("/press");
  };

  const handleNavigateCollection = () => {
    playClick();
    onToggleMenu();
    history.push("/collection");
  };
  const handleNavigateProfile = () => {
    playClick();
    onToggleMenu();
    history.push("/signup");
  };
  const handleNavigateSignUp = () => {
    playClick();
    onToggleMenu();
    history.push("/signup");
  };
  const handleNavigateLogin = () => {
    playClick();
    onToggleMenu();
    history.push("/login");
  };
  const handleNavigatelogout = () => {
    playClick();
    onToggleMenu();
    history.push("/");
    logout();
  };

  const handleScrollToProductData = () => {
    onToggleMenu();
    const element = document.getElementById("productData");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      window.scrollTo(0, element?.offsetTop - 100);
      // element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleScrollToMaterialData = () => {
    onToggleMenu();
    const element = document.getElementById("materialData");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      window.scrollTo(0, element?.offsetTop - 100);
      // element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleScrollToRecycling = () => {
    onToggleMenu();
    const element = document.getElementById("recyclingData");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      window.scrollTo(0, element?.offsetTop);
    }
  };
  const handleScrollToManufacturer = () => {
    onToggleMenu();
    const element = document.getElementById("manufacturer");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      window.scrollTo(0, element?.offsetTop - 100);
      // element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (pathname === "/saturn05nfc") {
    return (
      <FullHeightContainer>
        <StyledText onClick={() => handleScrollToProductData()}>
          PRODUCT DATA
        </StyledText>
        <StyledText onClick={() => handleScrollToMaterialData()}>
          MATERIAL DATA
        </StyledText>
        <StyledText onClick={() => handleScrollToRecycling()}>
          RECYCLING
        </StyledText>
        <StyledText onClick={() => handleScrollToManufacturer()}>
          MANUFACTURER
        </StyledText>
      </FullHeightContainer>
    );
  }

  if (user) {
    return (
      <FullHeightContainer>
        <StyledText onMouseEnter={play} onClick={handleNavigateCollection}>
          The collection
        </StyledText>
        <StyledText onMouseEnter={play} onClick={handleNavigatePress}>
          MEDIA & PRESS
        </StyledText>
        <StyledText onMouseEnter={play} onClick={handleNavigateProfile}>
          My Profile
        </StyledText>
        <StyledText onMouseEnter={play} onClick={handleNavigatelogout}>
          Logout
        </StyledText>
      </FullHeightContainer>
    );
  }

  return (
    <FullHeightContainer>
      <StyledText onMouseEnter={play} onClick={handleNavigateLogin}>
        Login
      </StyledText>
      <StyledText onMouseEnter={play} onClick={handleNavigateSignUp}>
        Sign up
      </StyledText>
      <StyledText onMouseEnter={play} onClick={handleNavigateCollection}>
        The collection
      </StyledText>
      <StyledText onMouseEnter={play} onClick={handleNavigatePress}>
        MEDIA & PRESS
      </StyledText>
    </FullHeightContainer>
  );
};
export default Menu;
