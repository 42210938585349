import React from "react";
import { Logo, User, Speaker, SpeakerOff } from "../../img";
import Sound from "react-sound";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Box, Hidden } from "@material-ui/core";
import { useAuth } from "../../contexts/authContext";
import useSound from "use-sound";
import { useLocation } from "react-router-dom";
//@ts-ignore
import audio from "../../audio/hover.mp3";
//@ts-ignore
import click from "../../audio/click.mp3";
import { theme } from "../../Theme";

const StyledHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 36px;
  margin-bottom: 70px;
  ${theme.breakpoints.down("sm")} {
    margin-bottom: 40px;
  }
  ${theme.breakpoints.down("xs")} {
    margin-bottom: 40px;
    height: 40px;
    padding-top: 20px;
  }
`;
const StyledLogo = styled(Logo)`
  cursor: pointer;
  width: auto;
  ${theme.breakpoints.down("xs")} {
    height: 37px;
  }
`;
const StyledSound = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledUserName = styled.p``;

const StyledUserContainer = styled(Box)`
  margin-right: 30px;
  cursor: pointer;
  p {
    margin: 0 15px 0 0;
    font-size: 16px;
    line-height: 19px;
  }
`;
const StyledBurguer = styled.div`
  width: 29px;
  height: 20px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      width: 70%;
      top: 9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 17px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0px;
        left: 0px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 20px;
        left: 0px;
      }
    }
  }
`;
type HeaderProps = {
  setIsPlaying: Function;
  isPlaying: string;
  onToggleMenu: () => void;
  showMenu: boolean;
};
const Header = ({
  setIsPlaying,
  isPlaying,
  showMenu,
  onToggleMenu,
}: HeaderProps) => {
  const { user } = useAuth();
  const [play] = useSound(audio);
  const [playClick] = useSound(click);
  const { pathname } = useLocation();

  let history = useHistory();

  const handleGoToHome = () => {
    playClick();
    if (showMenu) onToggleMenu();
    history.push("/");
  };

  const handleUser = () => {
    playClick();
    if (showMenu) onToggleMenu();
    history.push("/signup");
  };

  const handleSound = () => {
    switch (isPlaying) {
      case Sound.status.STOPPED:
        setIsPlaying(Sound.status.PLAYING);
        break;
      case Sound.status.PLAYING:
        setIsPlaying(Sound.status.STOPPED);
        break;
      default:
        break;
    }
  };
  const handleBurguer = () => {
    playClick();
    onToggleMenu();
  };

  return (
    <>
      <StyledHeader>
        <StyledLogo
          data-aos="fade-zoom-in"
          data-aos-duration="1000"
          data-aos-offset="0"
          onClick={handleGoToHome}
          onMouseEnter={play}
        />
        {isPlaying === "PLAYING" && pathname !== "/saturn05nfc" && (
          <StyledSound
            position="relative"
            data-aos="fade-zoom-in"
            data-aos-duration="1000"
            data-aos-offset="0"
            onClick={handleSound}
            onMouseEnter={play}
          >
            <Speaker />
            <div id="bars">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </StyledSound>
        )}
        {isPlaying === "STOPPED" && pathname !== "/saturn05nfc" && (
          <StyledSound
            position="relative"
            data-aos="fade-zoom-in"
            data-aos-duration="1000"
            data-aos-offset="0"
            onClick={handleSound}
            onMouseEnter={play}
          >
            <SpeakerOff />
          </StyledSound>
        )}

        {/* <StyledSound
          data-aos="fade-zoom-in"
          data-aos-duration="1000"
          data-aos-offset="0"
          onClick={handleSound}
          onMouseEnter={play}
        /> */}

        <Box display="flex" alignItems="center">
          <Hidden xsDown>
            {user && (
              <StyledUserContainer
                onClick={handleUser}
                display="flex"
                alignItems="center"
                onMouseEnter={play}
              >
                <StyledUserName onMouseEnter={play}>
                  {user?.user_metadata?.name}
                </StyledUserName>
                <User />
              </StyledUserContainer>
            )}
          </Hidden>
          <StyledBurguer
            onMouseEnter={play}
            data-aos="fade-zoom-in"
            data-aos-duration="1000"
            data-aos-offset="0"
            onClick={handleBurguer}
            id="nav-icon4"
          >
            <span></span>
            <span></span>
            <span></span>
          </StyledBurguer>
        </Box>
      </StyledHeader>
    </>
  );
};
export default Header;
