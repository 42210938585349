import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import styled from "styled-components";
import { theme } from "../Theme";
const StyledSlide = styled(Fade)`
  .images-wrap {
    width: 100% !important;
  }
`;
const StyledImg = styled.img`
  width: 100% !important;
`;
const StyledSliderContainer = styled(Box)`
  margin-top: 120px;
  margin-top: 120px;
  ${theme.breakpoints.down("sm")} {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;
interface SliderProps {
  images?: string[];
  duration?: number;
  canSwipe?: boolean;
}
const Slider: FC<SliderProps> = ({ images, duration = 5000, canSwipe }) => {
  return (
    <StyledSliderContainer marginY="120px" className="slide-container">
      <StyledSlide
        canSwipe={canSwipe}
        duration={duration}
        arrows={false}
        pauseOnHover={false}
      >
        {images?.map((image, index) => (
          <div key={index} className="each-fade">
            <div>
              <StyledImg src={image} alt={`slideImage${index + 1}`} />
            </div>
          </div>
        ))}
      </StyledSlide>
    </StyledSliderContainer>
  );
};
export default Slider;
