import { Planet, Planets } from "../../helper";

export const planetsInformation: Planet[] = [
  {
    code: "0.3",
    name: Planets.MERCURY,
    fileLink: "",
    location: "",
    adjectives: [],
    station: "",
    rocket: "",
    destination: "",
    slider1: [],
    slider2: [],
    slider3: [],
    disabled: true,
  },
  {
    code: "0.2",
    name: Planets.VENUS,
    fileLink: "",
    location: "",
    adjectives: [],
    station: "",
    rocket: "",
    destination: "",
    slider1: [],
    slider2: [],
    slider3: [],
    disabled: true,
  },
  {
    code: "0.8",
    name: Planets.EARTH,
    fileLink: "",
    location: "",
    adjectives: [],
    station: "",
    rocket: "",
    destination: "",
    slider1: [],
    slider2: [],
    slider3: [],
    disabled: true,
  },
  {
    code: "0.4",
    name: Planets.MARS,
    fileLink: "",
    location: "",
    adjectives: [],
    station: "",
    rocket: "",
    destination: "",
    slider1: [],
    slider2: [],
    slider3: [],
    disabled: true,
  },
  {
    code: "0.1",
    name: Planets.JUPITER,
    fileLink: "/files/jupiter.pdf",
    location: "PORTUGAL_ ALENTEJO",
    adjectives: [
      "Ancient",
      "Amphora",
      "Alentejo",
      "Clay",
      "Distinct",
      "Indigenous",
      " Passion",
      "Portugal",
      "Singular",
      "Unique",
    ],
    station:
      "THE ALENTEJO, SOUTH OF PORTUGAL, WHERE HERDADE DO ROCIM'S INDIGENOUS GRAPES ARE SUSTAINABLY-GROWN, RESPECTING THE SOCIAL, CULTURAL AND NATURAL MATRIX OF THE REGION.",
    rocket:
      "IN THE SHAPE OF A BOTTLE, YOU WILL TASTE A TALHA WINE - AN ANCESTRAL PORTUGUESE METHOD, USING AMPHORAS TO AGE THE WINE. JUPITER WAS FERMENTED AND THEN REMAINED IN THE TALHAS FOR FOUR YEARS. ROMANS WERE THE FIRST TO DISCOVER THE BENEFITS OF THIS ANCIENT MANNER, MORE THAN 2,000 YEARS AGO.",
    destination:
      "A JOURNEY FOR THE SENSES, RESERVED TO THE ONES WHO VALUE HISTORY, CULTURE AND TRADITION IN A WINE.",

    slider1: [
      "../images/jupiter/slider1/1.jpg",
      "../images/jupiter/slider1/2.jpg",
      "../images/jupiter/slider1/3.jpg",
      "../images/jupiter/slider1/4.png",
      "../images/jupiter/slider1/5.png",
    ],
    slider2: ["../images/jupiter/slider2/1.png"],
    slider3: [
      "../images/jupiter/slider3/1.png",
      "../images/jupiter/slider3/2.png",
      "../images/jupiter/slider3/3.png",
      "../images/jupiter/slider3/4.png",
      "../images/jupiter/slider3/5.png",
      "../images/jupiter/slider3/6.png",
    ],
    disabled: false,
    release: "RELEASED DATE_ SPRING 2021",
  },
  {
    code: "0.5",
    name: Planets.SATURN,
    fileLink: "/files/saturn.pdf",
    location: "GERMANY_ MOSEL",
    adjectives: [
      "RIESLING",
      "EXPRESSIVE",
      "MOSEL VALLEY",
      "COMPLEX",
      "RED SLATE SOIL MINERAL",
      "ERDENER TREPPCHEN",
      "GERMANY",
      "NATURAL",
      "MUSCULAR",
    ],
    station:
      "“ERDENER TREPPCHEN” IS A VERY DISTINCTIVE GROSSE LAGE (GRAND CRU) VINEYARD IN THE MOSEL VALLEY. IT IS ONE OF THE FEW VINEYARD SITES IN THE REGION THAT IS BASED ON RED SLATE. THESE ORIGINAL, UNCRAFTED VINES ARE OVER 130 YEARS OLD AND ARE EXTREMELY WELL ADAPTED TO THEIR PARTICULAR ENVIRONMENT.",
    rocket:
      "SURPRISINGLY FRESH AND YOUTHFUL, BUT AROMATICALLY COMPLEX AND DEEPLY EXPRESSIVE OF ITS RED SLATE BIRTHPLACE. THE INSISTENT MINERALITY OF “ERDENER TREPPCHEN” IS READILY APPARENT, YET SEAMLESSLY WOVEN INTO THE EARTHY TEXTURE OF THE WINE.",
    destination:
      "WITH A FOCUS ON THE OLD, THE ESTATE OWNS SOME OF THE MOSEL VALLEY’S BEST-RATED VINEYARDS, THE WINES CLEARLY EXPRESS THEIR COOL-CLIMATE ORIGINS THROUGH ENVIRONMENTALLY SENSITIVE VITICULTURE, STRICT HARVEST SELECTION AND GENTLE CELLAR PRACTICES.",
    slider1: [
      "/images/saturn/slider1/4.jpg",
      "/images/saturn/slider1/5.jpg",
      "/images/saturn/slider1/6.jpg",
    ],
    slider2: [],
    slider3: [],
    nfc: [
      "/images/saturn/nfc_saturn_01.jpg",
      "/images/saturn/nfc_saturn_02.jpg",
      "/images/saturn/nfc_saturn_03.jpg",
      "/images/saturn/nfc_saturn_04.jpg",
    ],
    disabled: false,
    release: "RELEASED DATE_ AUTUMN 2023",
  },
  {
    code: "0.7",
    name: Planets.URANUS,
    fileLink: "/files/uranus.pdf",
    location: "SPAIN_ PRIORAT",
    adjectives: [
      "SOUL",
      "MEDITERRANEAN",
      "PRIORAT",
      "UNPARALLELED",
      "INFUSION",
      "TRANSPARENT",
      "ELEGANCE",
      "SPAIN",
      "SUSTAINABLE",
      "FINESSE",
    ],
    station:
      "PRIORAT, A DYNAMIC WINE REGION IN CATALUNYA WITH THE INTENSE MEDITERRANEAN SUN IS THE HOME FOR DOMINIK HUBERT AND TERROIR AL LIMIT, WHERE THE METICULOUS ORGANIC AND BIODYNAMIC VITICULTURE LEADS HIM TO ONE OF THE MOST OUTSTANDING OF THE REGION",
    rocket:
      "THE LIQUID IS NONETHELESS THE LIFE PROOF AND THE FINAL RESULT OF A MINIMAL INTERVENTION, ATTENTION TO DETAIL IN EVERY INCH OF THE WINEMAKING PROCESS. THE CAREFUL HANDLING OF THESE GRAPES GAVE US URANUS, A WINE WITH A LONG LIFE TO LIVE.",
    destination:
      "THE FINAL STOP IS STILL UNKNOWED HOWEVER FOR NOW AN EXPLOSION OF FLAVOURS AND AROMAS WILL LEAD YOU TO ANOTHER DIMENSION WITH THE POWER TO SURVIVE TO MANY GALAXY BATTLES.",
    slider1: ["/images/uranus/slider1/1.jpg", "/images/uranus/slider1/2.jpg"],
    slider2: [],
    slider3: [],
    disabled: false,
    release: "RELEASED DATE_ AUTUMN 2022",
  },
  {
    code: "0.6",
    name: Planets.NEPTUNE,
    fileLink: "",
    location: "",
    adjectives: [],
    station: "",
    rocket: "",
    destination: "",
    slider1: [],
    slider2: [],
    slider3: [],
    disabled: true,
  },
  {
    code: "0.9",
    name: Planets.PLUTO,
    fileLink: "",
    location: "",
    adjectives: [],
    station: "",
    rocket: "",
    destination: "",
    slider1: [],
    slider2: [],
    slider3: [],
    disabled: true,
  },
];
