import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useAuth } from "../../contexts/authContext";
import { PrimaryBtn } from "../../shared/Buttons";
import CountDownTimer from "../home/components/CountDownTimer";
import { Logo, UranusFinalDestination } from "../../img";
import { theme } from "../../Theme";
import useSound from "use-sound";
//@ts-ignore
import click from "../../audio/click.mp3";

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-top: 70px;
`;

const StyledH3 = styled.h3`
  margin-bottom: 50px;
  ${theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }
`;
const StyledText = styled.p`
  font-size: 18px;
  letter-spacing: 0.2em;
  margin-top: 30px;
`;

const StyledLink = styled.a`
  font-size: 18px;
  letter-spacing: 0.2em;
  display: block;
  color: ${theme.palette.primary.light};
  margin: 30px 0 100px 0;
  ${theme.breakpoints.down("md")} {
    margin: 30px 0 50px 0;
  }
`;

const StyledFinalDestinationImage = styled(UranusFinalDestination)`
  width: 300px;
  margin-top: 20px;
  display: block;
  margin-inline: auto;
`;

const StyledLogo = styled(Logo)`
  display: block;
  margin-inline: auto;
  margin-bottom: 40px;
`;

const FinalDestination = () => {
  const ref = useRef(null);
  const [textVisible, setTextVisible] = useState(false);

  const handleClick = () => {
    setTextVisible(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box textAlign="center">
      <StyledH3 data-aos="fade-up" data-aos-duration="1500">
        SECOND PLANET LAUNCH EVENT 26TH OCTOBER 2022
      </StyledH3>
      {/* <div data-aos="fade-up" data-aos-duration="1800">
        <CountDownTimer stringDate="October 26, 22 13:00:00" />
      </div> */}

      <PrimaryBtn
        display="block"
        marginX="auto"
        marginY="50px"
        label="FINAL DESTINATION"
        onClick={handleClick}
        data-aos="fade-up"
        data-aos-duration="2000"
      />
      <StyledFinalDestinationImage
        data-aos="fade-up"
        data-aos-duration="2200"
      />
      {textVisible && (
        <>
          <StyledText ref={ref} data-aos="zoom-in" data-aos-duration="2000">
            {/* To be revealed on the 25th of October */}
            Very exclusive venue at 20h <br />
            Dress code: Dress to impress

          </StyledText>
          <StyledText ref={ref} data-aos="zoom-in" data-aos-duration="2000">
            {/* To be revealed on the 25th of October */}
            LUGAR DOMO 360º. <br />
            ESCUELA TÉCNICA SUPERIOR DE INGENIERÍA AGRONÓMICA, ALIMENTARIA Y DE
            BIOSISTEMAS,
            <br />
            Av. Puerta de Hierro, 2, 28040 Madrid
          </StyledText>
          <StyledLink
            data-aos="zoom-in"
            data-aos-duration="2100"
            href="https://g.page/NewMediaFulldome?share"
            target="_blank"
          >
            Google Maps
          </StyledLink>
        </>
      )}

      <StyledLogo data-aos="fade-up" data-aos-duration="2500" />
    </Box>
  );
};
export default FinalDestination;
