import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import db from "../../../../config/firebase.config";
import styled from "styled-components";
import background from "../../../../img/codeContainer.svg";
import MainInput from "../../../../shared/Input";
import { useHistory } from "react-router";
import useSound from "use-sound";

//@ts-ignore
import success from "../../../../audio/success.mp3";
//@ts-ignore
import typing from "../../../../audio/type.mp3";
//@ts-ignore
import codeError from "../../../../audio/fail.mp3";
//@ts-ignore
import click from "../../../../audio/click.mp3";
//@ts-ignore
import hover from "../../../../audio/hover.mp3";

import { theme } from "../../../../Theme";

type CodeProps = {
  setCode: Function;
};

const SignUpContainer = styled.div`
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -170px;
  flex-direction: column;
  ${theme.breakpoints.down("sm")} {
    margin-top: -100px;
  }
`;

const CodeContainer = styled.div`
  margin-top: 40px;
  width: 337px;
  height: 90px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
`;

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
  },
  subtitle: {
    fontSize: 18,
  },
  messageContainer: {
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
    height: 10,
  },
  errorMessage: {
    color: "#E04229",
  },
  successMessage: {
    color: "#36C35D",
  },
  lowerContainer: {
    height: 100,
  },
  link: {
    opacity: 0.5,
    fontWeight: 200,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const CodeSignUp = ({ setCode }: CodeProps) => {
  const [message, setMessage] = useState({ type: "", message: "" });
  const [sound, setSound] = useState(typing);
  const [play] = useSound(sound);
  const [playHover] = useSound(hover);
  const [playClick] = useSound(click);

  const handleCodeInput = async (evt) => {
    setMessage({ type: "", message: "" });
    let code = evt.target.value;
    setSound(typing);
    play();
    if (code.length < 4) return;
    const response = db.collection("codes").doc(code);
    const data = await response.get();
    if (!data.exists) {
      setSound(codeError);
      play();
      setMessage({ type: "error", message: "WRONG CODE" });
    } else if (data.data()?.used && !data.data()?.master) {
      setSound(codeError);
      play();
      setMessage({ type: "error", message: "CODE ALREADY USED" });
    } else if (data.data()?.master || (data.data() && !data.data()?.used)) {
      setSound(success);
      play();
      setMessage({ type: "success", message: "SUCCESS" });
      setTimeout(() => {
        setCode(code);
      }, 1000);
    }
  };
  const classes = useStyles();
  let history = useHistory();

  const handleGoToLogin = () => {
    playClick();
    history.push("/login");
  };

  return (
    <SignUpContainer>
      <div className={classes.container}>
        <div className={classes.title}>
          enter your code to live the experience!
        </div>
        <CodeContainer style={{ backgroundImage: `url(${background})` }}>
          <MainInput
            id="standard-basic"
            onChange={handleCodeInput}
            InputProps={{ disableUnderline: true }}
            autoComplete="off"
            inputProps={{
              maxLength: 5,
              style: {
                textAlign: "center",
                padding: "35px",
                fontSize: 24,
                textTransform: "capitalize",
              },
            }}
            placeholder={"CODE"}
          />
        </CodeContainer>
        <div className={classes.lowerContainer}>
          <div
            className={`${classes.messageContainer} ${
              message.type === "error"
                ? classes.errorMessage
                : classes.successMessage
            }`}
          >
            {message.message}
          </div>
          <div className={classes.subtitle}>
            Already have an account?{" "}
            <span
              onMouseEnter={() => playHover()}
              className={classes.link}
              onClick={handleGoToLogin}
            >
              Click here to sign in
            </span>
          </div>
        </div>
      </div>
    </SignUpContainer>
  );
};
export default CodeSignUp;
