import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Login from "./pages/auth/signIn";
import RecoverPassword from "./pages/auth/recoverPassword";
import Signup from "./pages/auth/signUp";
import Collection from "./pages/collection";
import CollectionDetail from "./pages/collection/CollectionDetail";
import FinalDestination from "./pages/finalDestination";
import Press from "./pages/press";
import Home from "./pages/home";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SaturnNfc from "./pages/nfc/Saturn";

export const Routes = () => {
  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition timeout={300} classNames="fade" key={location.key}>
        <Switch location={location}>
          <Route exact path="/collection/:id" component={CollectionDetail} />

          <Route exact path="/collection" component={Collection} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/recover-password" component={RecoverPassword} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/final-destination" component={FinalDestination} />
          <Route exact path="/press" component={Press} />
          <Route exact path="/saturn05nfc" component={SaturnNfc} />
          <Route path="/" component={Home} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
