import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled, { css } from "styled-components";

//@ts-ignore

import { theme } from "../Theme";
import { Planet } from "../helper";
import Planetimage from "./PlanetImage";

const StyledCode = styled.p<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    font-size: 12px;
    line-height: 21px;
    color: ${disabled
      ? theme.palette.secondary.dark
      : theme.palette.primary.light};
    margin-bottom: 0px;
  `};
`;
const StyledTitle = styled.p<{ disabled?: boolean }>`
  margin-top: 0px;
  margin-bottom: 0;
  ${({ disabled }) => css`
    font-size: 24px;
    line-height: 28px;
    color: ${disabled
      ? theme.palette.secondary.dark
      : theme.palette.primary.light};
    letter-spacing: 0.2em;
  `};
`;
const StyledBox = styled(Box)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};
    cursor: pointer;
  `};
`;
const StyledPlanetImage = styled(Planetimage)`
  height: 44px;
  width: 44px;
`;
const StyledLocation = styled.p`
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.2em;
`;

interface PlanetWelcomeCardProps {
  planet: Planet;
  onPlanetclick?: (planet: Planet) => void;
}

const PlanetWelcomeCard: FC<PlanetWelcomeCardProps> = ({
  planet,
  onPlanetclick = () => null,
}) => {
  //   const [play] = useSound(audio);
  return (
    <StyledBox
      disabled={planet.disabled}
      onClick={() => onPlanetclick(planet)}
      //   onMouseEnter={play}
      mb="40px"
      textAlign="center"
      className="planet"
    >
      <StyledPlanetImage planetName={planet.name} />
      <StyledCode disabled={planet.disabled}>
        {"code " + planet.code}
      </StyledCode>
      <StyledTitle disabled={planet.disabled}>{planet.name}</StyledTitle>
      {!planet.disabled && <StyledLocation>{planet.location}</StyledLocation>}
      <StyledLocation>{planet.release}</StyledLocation>
    </StyledBox>
  );
};
export default PlanetWelcomeCard;
