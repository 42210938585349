import React, { useState, createContext, useEffect } from "react";
import auth0 from "auth0-js";
import * as params from "../auth0-params.json";
import db from "../config/firebase.config";

var auth0Client = new auth0.WebAuth({
  domain: params.domain,
  clientID: params.clientId,
  audience: params.apiAudience,
  redirectUri: params.callbackUrl,
  scope: params.scope,
  responseType: params.responseType,
});

export interface AuthContextInterface {
  token: string | undefined;
  user: auth0.Auth0UserProfile | null;
  login: (email: string, password: string) => Promise<any>;
  register: (email: string, password: string, userMetadata: {}) => Promise<any>;
  logout: () => void;
  updateUser: (userId: string, userMetadata: {}) => void;
  changePassword: (email: string) => void;
}

const defaultContext: AuthContextInterface = {
  token: undefined,
  user: null,
  login: async () => Promise,
  register: async () => Promise,
  logout: () => {},
  updateUser: () => {},
  changePassword: () => {},
};

const AuthContext = createContext<AuthContextInterface>(defaultContext);
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<auth0.Auth0UserProfile | null>(null);
  const [token, setToken] = useState(
    sessionStorage.getItem("token") || undefined
  );

  useEffect(() => {
    const userString = sessionStorage.getItem("user");
    if (userString) setUser(JSON.parse(userString));
  }, []);

  useEffect(() => {
    if (token && token?.length > 0) {
      auth0Client.client.userInfo(token, function (err, user) {
        // sessionStorage.setItem("user", JSON.stringify(user));
        var auth0Manage = new auth0.Management({
          clientId: params.clientId,
          token: token,
          domain: params.domain,
          audience: params.apiAudience,
          scope: params.scope,
        });
        auth0Manage.getUser(user?.sub, function (err, user) {
          sessionStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        });
        // setUser(user);
      });
    }
  }, [token]);

  const login = async (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      auth0Client.client.login(
        {
          realm: params.realm,
          username: email,
          password,
        },
        async (err: any, authResult: any) => {
          if (err) {
            reject(err);
            return err;
          }
          if (authResult) {
            sessionStorage.setItem("token", authResult.accessToken);
            setToken(authResult.accessToken);
            resolve(authResult);
          }
        }
      );
    });
  };

  // register the user
  const register = (email: string, password: string, userMetadata: {}) => {
    return new Promise((resolve, reject) => {
      auth0Client.signupAndAuthorize(
        {
          email: email,
          password: password,
          userMetadata: userMetadata,
          connection: params.realm,
        },
        function (err, res) {
          if (err) reject(err);
          else {
            if (userMetadata) {
              db.collection("codes")
                .doc(userMetadata["code"])
                .get()
                .then((data) => {
                  let master = false;
                  if (data.data()?.master) master = true;
                  db.collection("codes")
                    .doc(userMetadata["code"])
                    .set({ used: true, master: master })
                    .then(() => {
                      sessionStorage.setItem("token", res.accessToken);
                      setToken(res.accessToken);
                      resolve(res);
                      return res;
                    });
                });
            }
          }
        }
      );
    });
  };

  const updateUser = (userId: string, userMetadata: {}) => {
    var auth0Manage = new auth0.Management({
      clientId: params.clientId,
      token: token,
      domain: params.domain,
      audience: params.apiAudience,
      scope: "update:current_user_metadata",
    });
    auth0Manage.patchUserMetadata(userId, userMetadata, function (err, res) {
      sessionStorage.setItem("user", JSON.stringify(res));
      setUser(res);
    });
  };
  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    setUser(null);
    setToken(undefined);
  };

  const changePassword = (email: string) => {
    auth0Client.changePassword(
      {
        connection: params.realm,
        email: email,
      },
      function (err, resp) {
        if (err) {
          console.log(err);
        } else {
          console.log(resp);
        }
      }
    );
  };

  const contextValue = {
    token,
    user,
    login,
    register,
    logout,
    updateUser,
    changePassword,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
