import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Astronote, PressStar } from "../../img";
import { PrimaryBtn } from "../../shared/Buttons";

const StyledPressStar = styled(PressStar)`
  margin-top: 76px;
  margin-bottom: 50px;
  display: block;
  margin-inline: auto;
  max-width: 100%;
`;
const StyledAStronote = styled(Astronote)`
  width: 145px;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
`;
const StyledH4 = styled.h4`
  margin-top: 30px;
`;

const Press = () => {
  return (
    <Box textAlign="center">
      <StyledAStronote data-aos="zoom-in" data-aos-duration="1800" />
      <h1 data-aos="fade-up" data-aos-duration="2000">
        MEDIA & PRESS
      </h1>
      <StyledH4 data-aos="fade-up" data-aos-duration="2400">
        WE HAVE GATHERED ALL THE NECESSARY ASSETS. <br />
        YOU CAN FIND HERE ALL THE INFORMATION NEEDED ABOUT WINES FROM ANOTHER
        WORLD.
      </StyledH4>

      <Link
        to="/files/WFAW_PressKit.zip"
        target="_blank"
        download={true}
        data-aos="fade-up"
        data-aos-duration="2200"
      >
        <PrimaryBtn
          marginTop="60px"
          onClick={() => null}
          label="Download Kit"
        />
      </Link>
      <StyledPressStar data-aos="zoom-in" data-aos-duration="2200" />
    </Box>
  );
};
export default Press;
