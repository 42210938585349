import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useAuth } from "../../../../contexts/authContext";
import { WelcomeMessageIcon } from "../../../../img";
import { PrimaryBtn } from "../../../../shared/Buttons";
import Spacer from "../../../../shared/Spacer";

const StyledTitle = styled.div`
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 0.2em;
  margin-top: 60px;
  margin-bottom: 40px;
`;
const Styledh4 = styled.h4`
  line-height: 34px;
  margin-bottom: 10px;
`;

const WelcomeMessage = () => {
  const { user } = useAuth();
  let history = useHistory();

  const handleNavigateToHome = () => {
    history.push("/collection");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box textAlign="center">
      <WelcomeMessageIcon />
      <StyledTitle>Welcome {user?.user_metadata?.name}</StyledTitle>
      <h4>
        WE ARE SO PLEASED YOU ACCEPTED TO JOIN US ON THIS VERY SPECIAL MISSION.
      </h4>
      <h4>
        YOU ARE AN EXCLUSIVE MEMBER OF OUR <br /> WINES FROM ANOTHER WORLD CLUB.
      </h4>
      <Spacer height="50px" />
      <Styledh4>YOU CAN EXPECT:</Styledh4>
      <Styledh4>&gt; A UNIQUE SENSORIAL EXPERIENCE WITH RARE WINES.</Styledh4>
      <Styledh4>&gt; ACCESS TO EXCLUSIVE OFFERS AND OPPORTUNITIES.</Styledh4>
      <Styledh4>&gt; A NETWORK OF EXTRAORDINARY PARTNERSHIPS.</Styledh4>
      <Styledh4>
        &gt; A DISTINCTIVE WINE EVENTS' AGENDA AROUND THE WORLD.
      </Styledh4>
      <PrimaryBtn
        marginTop="50px"
        label="engines on!"
        onClick={handleNavigateToHome}
      />
    </Box>
  );
};
export default WelcomeMessage;
