import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Planetimage from "../../shared/PlanetImage";
import Spacer from "../../shared/Spacer";
import { planetsInformation } from "./data";
import { CollectionDetails1, CollectionDetails2 } from "../../img";
import Slider from "../../shared/Slider";
import { PrimaryBtn } from "../../shared/Buttons";
import { theme } from "../../Theme";

const StyledCode = styled.p`
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 5px;
`;
const StyledName = styled.p`
  font-size: 28px;
  line-height: 33px;
  color: #fff;
  letter-spacing: 0.2em;
  margin: 0;
`;
const StyledAdjectives = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.2em;
  width: 85%;
  margin-top: 50px;
`;
const StyledImg = styled.img`
  width: 100%;
  margin-top: 100px;
  ${theme.breakpoints.down("sm")} {
    margin-top: 60px;
  }
`;
const StyledLabel = styled.p`
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 10px;
`;
const StyledText = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  margin-top: 0;
`;
const StyledAboutContainer = styled(Box)`
  width: 55%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

interface CollectionDetailProps {
  id: string;
}

const CollectionDetail = () => {
  let { id } = useParams<CollectionDetailProps>();
  const planet = planetsInformation.find((el) => el.name === id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Grid alignItems="center" container>
        <Grid item xs={12} md={5}>
          <Box display="flex" alignItems="center">
            <Planetimage planetName={planet?.name} />
            <Box ml="40px">
              <StyledCode>Code_{planet?.code}</StyledCode>
              <StyledName>{planet?.name}</StyledName>
            </Box>
          </Box>
          <StyledAdjectives>
            {planet?.adjectives.map((adjective, index) => {
              return (
                adjective +
                (planet?.adjectives.length === index + 1 ? "" : " / ")
              );
            })}
          </StyledAdjectives>
        </Grid>
        <Grid item xs={12} md={7}>
          <StyledImg
            src={`../images/${planet?.name}/1.png`}
            alt={planet?.name}
          />
        </Grid>
      </Grid>
      <StyledAboutContainer>
        <StyledLabel>Station</StyledLabel>
        <StyledText>{planet?.station}</StyledText>
        <Spacer height="25px" />
        <StyledLabel>Rocket</StyledLabel>
        <StyledText>{planet?.rocket}</StyledText>
        <Spacer height="25px" />
        <StyledLabel>Destination</StyledLabel>
        <StyledText>{planet?.destination}</StyledText>
        <Spacer height="40px" />
      </StyledAboutContainer>
      <Box textAlign="center">
        {planet?.name === "jupiter" && (
          <>
            <Slider images={planet?.slider1} />
            <CollectionDetails1 />
            <h5 style={{ marginTop: "100px" }}>
              Our partners from GeoSat have provided us the satellite images{" "}
              <br />
              from the vineyards plot, where Jupiter was born.
            </h5>
            <Slider images={planet?.slider2} />
            <CollectionDetails2 />
            <h5 style={{ marginTop: "100px" }}>
              Jupiter was born in Alentejo in Herdade do Rocim. <br /> ROCIM’s
              mission is to produce wines in a sustainable manner, respecting
              the social, cultural and natural matrix of the region.
            </h5>
            <Slider images={planet?.slider3} />
          </>
        )}

        {planet?.name === "uranus" && (
          <>
            {planet.slider1.map((img, index) => (
              <StyledImg
                key={index}
                data-aos="zoom-in"
                data-aos-duration="2000"
                src={img}
                alt="uranus"
              />
            ))}

            <h5 style={{ textAlign: "left", marginTop: "50px" }}>
              THE URANUS WINE IS BORN FROM THE UNION OF DOMINIK HUBER'S PASSION
              FOR SEARCHING UNIQUE PLOTS IN THE PRIORAT AND THE MONTSANT
              MOUNTAINS, BRINGING PURE MEDITERRANEAN ENERGY TO THE GLASS, AND
              THE PROFESSIONALISM AND “SAVOIR FAIR” OF CLAUDIO MARTINS, THE
              IDEOLOGIST OF THIS PROJECT.
            </h5>
            <h5 style={{ textAlign: "left", marginTop: "50px" }}>
              AS A QUEST TO HARNESS THE UNIQUE MINERALITY AND SOUL OF THE
              MOUNTAINS, DOMINIK FOUND THIS 75-YEAR-OLD VINEYARD OF GARNACHA
              PELUDA, GARNACHA BLANCA AND CARIÑENA, THAT THRIVE THERE SHOW AN
              UNPARALLELED BEAUTY AND ELEGANT FINESSE. SOMETHING THAT URANUS
              REFLECTS FAITHFULLY, TOGETHER WITH THE TERROIR OF THE FONTANALS
              PARCEL, WHERE IT COMES FROM; RED CLAY AND GYPSUM SOILS LOCATED AT
              800 M OF ELEVATION. VINIFICATION AND AGING ARE MADE TO MAINTAINING
              A CONSISTENT AND NATURAL CONNECTION WITH IT. INFUSION INSTEAD OF
              EXTRACTION, USING OF THE STEMS AND FINISH IN CONCRETE.
            </h5>
            <h5 style={{ textAlign: "left", marginTop: "50px" }}>
              THE REFINED BEAUTY OF THIS DRY RED IS LESS TASTE THAN EXPERIENCE.
              A WILD RIDE OF ENERGY, DEPTH AND COMPLEXITY. THE LITHE AROMAS AND
              FLAVORS MOVE LIKE A DANCER ACROSS THE NOSE AND PALATE FROM DEEP
              RED AND BLUE FRUIT TO STONE TO SPICE WITH A CONSISTENT VELVETY
              TEXTURE. YET THAT SEEMS ALMOST SECONDARY TO THE NEARLY
              TRANSCENDENTAL BALANCE AND INNER QUIET. THE WINE GREW FROM RESPECT
              FOR THE LAND AND THE CULTURE. IT BELONGS TO A PLACE AND A TIME,
              AND AS SUCH IS A TRUE MIRROR FOR THE POWER, MYSTERY AND BEAUTY OF
              ITS REGION.
            </h5>
          </>
        )}

        {planet?.name === "saturn" && (
          <>
            {planet.slider1.map((img, index) => (
              <StyledImg
                key={index}
                data-aos="zoom-in"
                data-aos-duration="2000"
                src={img}
                alt="saturn"
              />
            ))}
          </>
        )}
        {planet?.fileLink && (
          <Link to={planet?.fileLink} target="_blank" download={true}>
            <PrimaryBtn
              marginTop="60px"
              onClick={() => null}
              label="Technical sheet"
            />
          </Link>
        )}
      </Box>
    </div>
  );
};
export default CollectionDetail;
