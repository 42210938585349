import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f4f4f4",
      dark: "#1B1B1B",
      light: "#fff",
    },
    secondary: {
      main: "#191919",
      dark: "#4d4d4d",
    },
    error: {
      main: "#E04229",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        background: "transparent",
      },
    },
    MuiInputLabel: {
      filled: {
        transform: "translate(12px, 10px) scale(0.75)",
        "&$marginDense": {
          transform: "translate(12px, 7px) scale(0.75)",
        },
      },
      outlined: {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
  },
});
