import { TextField, TextFieldProps } from "@material-ui/core";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";
import { theme } from "../Theme";

const StyledTextField = styled(TextField)`
  background: transparent !important;
  color: white !important;
  font-size: 18px;
  text-transform: uppercase;
  max-width: 280px;
  width: 280px;
  ${space}
  ${layout}
  &:hover {
    .MuiInput-underline:before {
      border-bottom: 1px solid #767676;
    }
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #767676;
  }
  label {
    color: #8a8a8a !important;
    font-size: 14px;
  }
  input {
    color: white !important;
    font-size: 18px;
    text-transform: uppercase;
  }
  .MuiFormHelperText-root {
    color: ${theme.palette.error.main};
    font-size: 12px;
  }
`;

type InputProps = SpaceProps & LayoutProps & TextFieldProps;

const MainInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, id, type, label, ...restProps } = props;
  return (
    <StyledTextField
      ref={ref}
      className={className}
      label={label}
      // id="standard-basic"
      type={type}
      {...restProps}
    />
  );
});
export default MainInput;
